import Expander from "Clutch/Organisms/Expander/Expander";
import LandingPageLinkRack from "Clutch/Organisms/LandingPageLinkRack";

import styles from "./FlatCategorySection.module.scss";

const FlatCategorySection = ({ categories }) => {
  const links = categories.map((category) => {
    return {
      displayValue: category.category,
      imageUrl: category.representativeImage,
      url: category.shopUrl,
      noFollow: false,
    };
  });
  return (

    <LandingPageLinkRack
      containerClassName={styles.container}
      links={links}
    />

  );
};

export default FlatCategorySection;
