import LandingPageLinkRack from "Clutch/Organisms/LandingPageLinkRack";
import Expander from "Clutch/Organisms/Expander/Expander";
import styles from "./marketLinks.module.scss";

const RaceTypeLinkRack = ({ links }) => {
    if (!links.some(x => x.childLinks)) {
        return false
    }

    const flattenRaceTypes = () => {
        let result = links.flatMap(x => x.childLinks);
        result.sort((x, y) => { return x.priority - y.priority })
        return result;
    }

    return <LandingPageLinkRack containerClassName={styles.container} links={flattenRaceTypes()} isRaceTypeImage />



}
export default RaceTypeLinkRack