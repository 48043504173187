import Typography from "Clutch/Atoms/Typography";
import classNames from "classnames";

import styles from "./HeroSectionText.module.scss";

const HeroSectionText = ({
  className,
  text,
  size,
  centered,
  bold,
  as,
  onClick,
}) => {
  let textStyles = styles.text;
  let currentSize = 1;

  if (className) textStyles = classNames(className, styles.text);

  if (centered) textStyles = classNames(textStyles, styles.centered);

  if (size) currentSize = size;

  let TextComponent = (
    <Typography
      className={textStyles}
      tone={"contrast"}
      font={bold ? "bold" : "regular"}
      as={as}
      size={currentSize}
    >
      {text}
    </Typography>
  );

  if (onClick)
    TextComponent = (
      <div onClick={onClick}>
        {TextComponent}
      </div>
    );

  return TextComponent;
};

export default HeroSectionText;
