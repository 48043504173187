import { useEffect, useState } from "react";

import {
  fitmentStates,
  useFitmentStateContext,
} from "../../../providers/FitmentStateProvider";
import { useHomePage } from "../../../providers/HomePageProvider";
import HeroSectionText from "../hero-section-text/HeroSectionText";
import styles from "./MobileCta.module.scss";
import MobileGarageButton from "./mobile-garage-button/MobileGarageButton";
import MobileYmmSelector from "./mobile-ymm-selector/MobileYmmSelector";

const MobileCta = ({ audience, h1Override, suppressOtherFitment }) => {
  const { fitmentState } = useFitmentStateContext();
  const { hasCustomerProjects } = useHomePage();
  const [showYmmSelector, setShowYmmSelector] = useState(
    hasCustomerProjects ? false : true
  );
  let CtaComponent = null;
  let heroSectionTitleText = "Find Parts that Fit";

  useEffect(() => {
    if (fitmentState === fitmentStates.noVehiclesInGarage)
      setShowYmmSelector(true);
    else setShowYmmSelector(false);
  }, [fitmentState]);

  if (showYmmSelector) {
    CtaComponent = (
      <MobileYmmSelector
        audience={audience}
        suppressOtherFitment={suppressOtherFitment}
      />
    );
    heroSectionTitleText = "What Do You Drive?";
  } else CtaComponent = <MobileGarageButton />;

  return (
    <div className={styles.wrapper}>
      <HeroSectionText
        text={h1Override ?? heroSectionTitleText}
        size={1.5}
        bold
        as={h1Override ? "h1" : "p"}
      />
      {CtaComponent}
    </div>
  );
};

export default MobileCta;
