import { useEffect, useState } from "react";

import { useUserStore } from "@speedwaymotors/clutch/Stores/UserStore/UserStore";
import Gutters from "Clutch/Atoms/Gutters";
import Panel from "Clutch/Atoms/Panel";
import Svg from "Clutch/Atoms/Svg";
import useSegmentLeadCaptureEvent from "Clutch/Hooks/useSegment/segmentSpecificHooks/useSendLeadCaptureEvent";
import Button from "Clutch/Molecules/Button";
import ColorFinder from "Clutch/Utilities/ColorFinder";
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import ImpressionBoundary from "Clutch/UtilityComponents/ImpressionBoundary";
import styled from "styled-components";

import styles from "./EmailSection.module.scss";

const EmailSignUpSection = ({ type, dataTestId, audience }) => {
  const [isSignUpButtonLoading, setIsSignUpButtonLoading] = useState(false);
  const [shouldRender, setShouldRender] = useState(true);
  const { sendSmsAndEmailEvent } = useSegmentLeadCaptureEvent();
  const { color } = ColorFinder(null, null, "primary");

  let backgroundColor;
  if (type === "purple") backgroundColor = color;

  const isSignedUpForEmails = useUserStore(
    (x) => x.context.isSignedUpForEmails
  );
  const hydrated = useUserStore((x) => x.context.hydrated);

  useEffect(() => {
    if (hydrated && isSignedUpForEmails) setShouldRender(false);
  }, [hydrated, isSignedUpForEmails]);

  if (!shouldRender) return false;

  return (
    <ImpressionBoundary
      sectionName={dataTestId}
      additionalViewedFuncs={[
        () => sendSmsAndEmailEvent("Impression", dataTestId),
      ]}
      audience={audience}
    >
      <Wrapper layer={0} inverseTheme={true} color={backgroundColor}>
        <Gutters noVerticalPadding>
          <div className={styles.emailNewsLetterContainer}>
            <div className={styles.descriptionContainer}>
              <div className={styles.description}>
                <p className={"p p-color-contrast p-2_5"}>
                  <span className={"p p-color-contrast p-bold"}>$10 </span>Off
                  Your Next Order.
                </p>
                <p className={"p p-color-contrast p-1"}>
                  Sign up and receive the Best Deals and Expert Information
                </p>
              </div>
              <div className={styles.descriptionMobile}>
                <p className={"p p-color-contrast p-1_5 p-bold"}>$10 OFF</p>
                <p className={"p p-color-contrast p-0_75"}>
                  Your first order when you join our list.
                </p>
              </div>
            </div>
            <div className={styles.signUpContainer}>
              <Button
                className={styles.signUpButton}
                brand={"secondary"}
                fill
                size={"large"}
                text={"Sign Up"}
                href={"/emailsignup"}
                segmentEvent={createClickedSegmentEvent("EmailSignUp")}
                onClick={() => {
                  sendSmsAndEmailEvent("Started", dataTestId),
                    setIsSignUpButtonLoading(true);
                }}
                isWaiting={isSignUpButtonLoading}
                data-testid={"emailSignuButton"}
              />
            </div>
            <Svg className={styles.wheel} icon={"wheel"} tone={"superfluous"} />
          </div>
        </Gutters>
      </Wrapper>
    </ImpressionBoundary>
  );
};

const Wrapper = styled(Panel)`
  background-color: ${(props) => props.color};
`;

export default EmailSignUpSection;
