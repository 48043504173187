import { ClickTrap } from "Clutch/UtilityComponents/ImpressionBoundary/ImpressionBoundary";
import classNames from "classnames";
import Image from "next/image";

import {
  fitmentStates,
  useFitmentStateContext,
} from "../../providers/FitmentStateProvider";
import styles from "./HeroSection.module.scss";
import DesktopCta from "./desktop-cta/DesktopCta";
import MobileCta from "./mobile-cta/MobileCta";

const getHeroImageUrl = (audience) => {
  switch (audience) {
    case "race":
      return "https://content.speedwaymotors.com/HomePageImages/RACE-Header-1800-500.jpg";
    case "truck":
      return "https://content.speedwaymotors.com/HomePageImages/header-truck-1800-390-gradient.jpg";
    default:
      return "https://content.speedwaymotors.com/HomePageImages/Street-Header-w-gradient.png";
  }
};

const HeroSection = ({
  audience,
  suppressOtherFitment,
  imageOverride,
  h1Override,
  showGradient = true,
}) => {
  const { fitmentState } = useFitmentStateContext();
  const shouldUseLargerHeroImageForYmm =
    audience === "default" && fitmentState === fitmentStates.noVehiclesInGarage;

  let heroImageUrl = imageOverride?.length
    ? imageOverride
    : getHeroImageUrl(audience);

  let heroImageWrapperClassNames =
    audience !== "race" ? styles.heroImageWrapper : styles.raceImageWrapper;
  let heroWrapperClassNames = classNames(styles.heroBlock, "bg-dark");

  if (shouldUseLargerHeroImageForYmm) {
    heroImageWrapperClassNames = classNames(
      heroImageWrapperClassNames,
      styles.defaultImageWrapper
    );
    heroWrapperClassNames = classNames(
      heroWrapperClassNames,
      styles.defaultHeroBlock
    );
  }

  let HeroImageComponent = (
    <Image
      fill
      priority
      alt={""}
      loading={"eager"}
      className={styles.heroImage}
      src={heroImageUrl}
    />
  );

  if (shouldUseLargerHeroImageForYmm)
    HeroImageComponent = (
      <Image
        width={670}
        height={390}
        priority
        alt={""}
        loading={"eager"}
        className={styles.heroImage}
        src={
          audience === "default" ? "https://content.speedwaymotors.com/HomePageImages/Header-Default-mobile.jpg" : heroImageUrl
        }
      />
    );

  return (
    <ClickTrap sectionName={"hero_section"}>
      <div className={heroWrapperClassNames}>
        {showGradient && <div className={styles.gradient} />}
        <div className={heroImageWrapperClassNames}>{HeroImageComponent}</div>
        <DesktopCta
          audience={audience}
          suppressOtherFitment={suppressOtherFitment}
          h1Override={h1Override}
        />
        <MobileCta
          audience={audience}
          h1Override={h1Override}
          suppressOtherFitment={suppressOtherFitment}
        />
      </div>
    </ClickTrap>
  );
};

export default HeroSection;
