import Gutters from "Clutch/Atoms/Gutters";

import { useFitmentStateContext } from "../../../providers/FitmentStateProvider";
import HeroSectionText from "../hero-section-text/HeroSectionText";
import styles from "./DesktopCta.module.scss";
import YmmSelector from "./ymm-selector/YmmSelector";

const DesktopCta = ({ audience, suppressOtherFitment, h1Override }) => {
  const { setIsFindPartsClicked } = useFitmentStateContext();

  return (
    <div className={styles.container}>
      <Gutters noVerticalPadding className={styles.gutters}>
        <HeroSectionText
          className={styles.title}
          text={h1Override ?? "Find Parts that Fit"}
          size={2}
          as={h1Override ? "h1" : "p"}
          bold
          onClick={() => setIsFindPartsClicked(true)}
        />
        <HeroSectionText
          className={styles.subtitle}
          text={"Add your vehicle to find parts that fit."}
          size={1}
        />
        <YmmSelector
          audience={audience}
          suppressOtherFitment={suppressOtherFitment}
        />
      </Gutters>
    </div>
  );
};

export default DesktopCta;
