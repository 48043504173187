import { useContext, useState } from "react";

import Svg from "Clutch/Atoms/Svg";
import useSegment from "Clutch/Hooks/useSegment";
import Button from "Clutch/Molecules/Button";
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";

import AppName from "../../../../appName/appName";
import {
  fitmentStates,
  useFitmentStateContext,
} from "../../../../providers/FitmentStateProvider";
import { getVehiclePlaceholder } from "../../desktop-cta/ymm-selector/tab-content/tabContentUtils";
import styles from "./MobileGarageButton.module.scss";

const MobileGarageButton = ({ audience }) => {

  const { fitmentState, selectedCustomerProject, setCurrentTabForAudience } = useFitmentStateContext();

  const [loading, setLoading] = useState(false);

  const placeholder = getVehiclePlaceholder(selectedCustomerProject, audience);
  const { setYmmModal, dispatch: navigationDispatch } =
    useContext(NavigationContext);
  const { sendCustomSegmentTrackEvent } = useSegment();

  const contentClassNames = `${styles.content} p p-color-contrast p-bold`;
  let buttonProps = null;

  switch (fitmentState) {
    case fitmentStates.vehicleSelected:
      buttonProps = {
        onClick: (e) => {
          e.stopPropagation();
          setLoading(true);
          sendCustomSegmentTrackEvent(
            createClickedSegmentEvent(`${AppName}_Garage_Vehicle_Button_Mobile`)
          );
          window.location.href = selectedCustomerProject?.shopUrl ?? "/shop/all";
        },
        isWaiting: loading,
        children: (
          <div className={styles.vehicleSelectedWrapper}>
            <p
              className={`${styles.selectedCustomerProjectContent} ${styles.selectedCustomerProjectText} p p-color-contrast p-bold`}
              style={{ flexGrow: 1, textAlign: "left" }}
            >
              {placeholder}
            </p>
            <Svg
              size={1}
              icon={"chevron"}
              tone={"contrast"}
              style={{ marginLeft: "0.25rem", transform: "rotate(90deg)" }}
              onClick={(e) => {
                e.stopPropagation();
                setYmmModal(true);
              }}
            />
          </div>
        ),
      };
      break;
    case fitmentStates.noVehicleSelected:
      buttonProps = {
        onClick: (e) => {
          e.stopPropagation();
          navigationDispatch({
            type: "setGarageState",
            payload: {
              garageState: { isGarageModalOpen: true },
            },
          });
        },
        children: <p className={contentClassNames}>{placeholder}</p>,
      };

      break;
    case fitmentStates.noVehiclesInGarage:
      buttonProps = {
        ["data-testid"]: "add-vehicle-button",
        onClick: (e) => {
          e.stopPropagation();
          setCurrentTabForAudience(audience);
          setYmmModal(true);
        },
        children: (
          <div className={contentClassNames}>
            <Svg
              size={1}
              icon={"garage"}
              tone={"contrast"}
              style={{ marginRight: "0.25rem" }}
            />
            {placeholder}
          </div>
        ),
      };
      break;
  }

  return (
    <Button
      brand={"secondary"}
      size={"small"}
      fill
      type={"button"}
      className={styles.button}
      {...buttonProps}
      segmentEvent={createClickedSegmentEvent('MobileGarageHomePage')}
    />
  );
};

export default MobileGarageButton;
