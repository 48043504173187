import { useInView } from "react-intersection-observer";

import Tabs from "Clutch/Components/Tabs/Tabs";
import Expander from "Clutch/Organisms/Expander/Expander";

import modularSectionTypes from "../../../constants/modularSectionTypes";
import { useHomePage } from "../../../providers/HomePageProvider";
import styles from "./ShopLinkSection.module.scss";
import ShopLinkTabContent from "./shop-link-tab-content/ShopLinkTabContent";

const linkType = {
  Platform: "Platforms",
  Model: "Models",
  Make: "Makes",
  EngineFamily: "Engine Families",
  Transmission: "Transmissions",
  RearEndFamily: "Rear Axle Families",
  RaceType: "Race Types",
};

const ShopLinkSection = ({ audience }) => {
  const { modularSections } = useHomePage();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "200px 0px",
  });

  const shopLinks = modularSections.find(
    (x) => x.type === modularSectionTypes.shopLinks
  ).shopLinks;

  const items = (shopLinks || []).map((seoLink) => {
    return {
      label: linkType[seoLink.type],
      key: `shop_link_${seoLink.type}`,
      content: (
        <ShopLinkTabContent seoLink={seoLink} areImagesLoading={!inView} />
      ),
    };
  });

  return (
    <>
      <div ref={ref} />
      <Expander
        closedHeight={"450px"}
        buttonProps={{ intent: false, fill: false, invert: false }}
        showOnMobileOnly
        testid={"shop_links"}
      >
        <div className={styles.tabsContainer}>
          <Tabs
            centered
            brand={"primary"}
            items={items}
            size={1}
            seoFocused
            useDropdown
            dataTestId={"shop_link"}
            className={styles.tabContent}
            onChange={() => {}}
            selectLabel={"shop links"}
          />
        </div>
      </Expander>
    </>
  );
};

export default ShopLinkSection;
