import { isValidElement, useContext } from "react";

import Gutters from "Clutch/Atoms/Gutters";
import Panel from "Clutch/Atoms/Panel";
import Typography from "Clutch/Atoms/Typography";
import ImpressionBoundary from "Clutch/UtilityComponents/ImpressionBoundary";
import { useThemeStore } from "Clutch/Stores/ThemeStore";
import classNames from "classnames";
import styled from "styled-components";

import styles from "./Section.module.scss";

const Section = ({
  className,
  title,
  shrinkTitleOnMobile,
  subtitle,
  extendGutters,
  noVerticalSpacing,
  children,
  inverseTheme,
  useH1Title,
  dataTestId,
  layer = 0,
  suppressImpressionBoundary,
  audience
}) => {
  const themeContext = useThemeStore(x => x)

  if (!children) return false

  let darkTheme = themeContext?.theme == "dark" || inverseTheme === true;

  let content = children;
  let heading = null;
  let SubTitleComponent = subtitle;

  const titleStyle = shrinkTitleOnMobile ? styles.titleMobile : styles.title;

  if (subtitle && !isValidElement(subtitle)) {
    SubTitleComponent = (
      <Typography
        size={1}
        font={"regular"}
        tone={"contrast"}
        className={darkTheme ? "bg-dark" : ""}
      >
        {subtitle}
      </Typography>
    );
  }

  if (title) {
    let TitleComponent;
    const titleClasses = darkTheme
      ? classNames("p p-bold bg-dark p-color-contrast", titleStyle)
      : classNames("p p-bold", titleStyle);
    if (useH1Title)
      TitleComponent = (
        <h1 className={titleClasses} data-testid={dataTestId}>
          {title}
        </h1>
      );
    else
      TitleComponent = (
        <h2 className={titleClasses} data-testid={dataTestId}>
          {title}
        </h2>
      );
    heading = (
      <div className={styles.headingContainer}>
        {TitleComponent}
        {SubTitleComponent}
      </div>
    );
  }


  if (!suppressImpressionBoundary) {
    content = (
      <section className={className}>
        <ImpressionBoundary sectionName={dataTestId} audience={audience}>
          <Panel layer={layer} inverseTheme={inverseTheme}>
            <Spacing
              className={!noVerticalSpacing ? styles.verticalSpacing : null}
              noVerticalPadding={noVerticalSpacing}
              noHorizontalPadding={extendGutters}
            >
              {heading}
              {children}
            </Spacing>
          </Panel>
        </ImpressionBoundary>
      </section>
    );
  } else {
    content = (
      <section className={className}>
        <Panel layer={layer} inverseTheme={inverseTheme}>
          <Spacing
            className={!noVerticalSpacing ? styles.verticalSpacing : null}
            noVerticalPadding={noVerticalSpacing}
            noHorizontalPadding={extendGutters}
          >
            {heading}
            {children}
          </Spacing>
        </Panel>
      </section>
    );
  }



  return content;
};

const Spacing = styled(Gutters)`
  ${(props) =>
    props.noVerticalPadding ? "padding-top: 0px; padding-bottom: 0px;" : null}
  ${(props) =>
    !props.noVerticalPadding
      ? `padding-top: 0.875rem;
        padding-bottom: 0.875rem;
        @media (min-width: 1335px) {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }`
      : null}
`;

export default Section;
