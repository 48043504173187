import React from "react";
import LandingPageLinkRack from "Clutch/Organisms/LandingPageLinkRack";

const ShopLinkTabContent = ({ seoLink, areImagesLoading }) => {
  const { links, seeAllText, seeAllUrl } = seoLink;

  let linksMapped = links.map((link) => {
    return {
      displayValue: link.displayName,
      url: link.url,
      noFollow: false,
      imageUrl: link.imageUrl
    };
  });


  return (
    <LandingPageLinkRack
      links={linksMapped}
      showMoreLink={{ url: seeAllUrl, text: seeAllText }}
      isRaceTypeImage={seoLink.type == "RaceType"}
      dataTestId={'shop_links'}
      areImagesLoading={areImagesLoading}
    />
  );
};

export default ShopLinkTabContent;
